import React, {useEffect, useState} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/App.css";
import "../assets/css/Actualities.css"
import "../assets/css/Header.css"
import {AppContainer} from "../components/AppContainer";
import {useNavigate} from "react-router-dom";
import {toastError} from "../components/toastError";
import {LoadingSpinner} from "../components/LoadingSpinner";
import TextEditor from "../components/TextEditor";

const Actualities = () => {

    const [articles, setArticles] = useState([])

    useEffect(() => {
        getVisibleArticles()
    }, []);

    function getVisibleArticles() {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getVisibleArticles`, {})
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setArticles(data)
            })
            .catch((error) => {
                toastError("Chyba pri načitávaní článkov!")
                return error;
            });
    }

    const Article = ({article}) => {

        const navigate = useNavigate();

        return (
            <div className="desktop-row-phone-col article-item rounded-4 w-100 my-3 p-2"
                 onClick={() => navigate("/aktuality/" + article._id)}>
                <div className="article-img-container">
                    <img
                        src={article.image_url.length > 0 ? article.image_url : "https://i.ibb.co/z24Mq1P/titulna-6.jpg"}
                        className="article-image" alt=""/>
                </div>
                <div className="article-content-container">
                    <>
                        <h4 className="font-700 color-3">{article.title}</h4>
                        <h6 className="font-300 color-2">{article.author + " " + article.date}</h6>
                    </>
                    <div className="phone-not-shown">
                        <TextEditor value={article.editorHtml}/>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <AppContainer
            title={"Aktuality"}
            content={
                <div className="window-content-container">
                    {articles.length > 0 ?
                        articles.map((item, index) => (
                            <Article key={index} article={item}/>
                        ))
                        :
                        <div className="app-container mt-5">
                            <LoadingSpinner/>
                        </div>
                    }
                </div>
            }
        />
    )
};

export default Actualities;
